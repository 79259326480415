import login from "@/router/login.js";
import account from '@/router/account.js';
import forgetPassword from '@/router/forget_password.js';
import register from '@/router/register.js';
import enter_data from '@/router/enter_data.js';
import home from "@/router/home.js";
import attractInvestment from '@/router/attract_investment.js';
import regulation from '@/router/regulation.js';
import root from '@/router/root.js';
import notFound from '@/router/404.js';
import meessage from '@/router/message.js';

const routes = [
  meessage,
  account,
  login,
  forgetPassword,
  register,
  enter_data,
  home,
  attractInvestment,
  regulation,
  root,
  notFound,
];

export default routes;
