import dealFilter from "./home/deal-filter.js";
import * as  dealFilter2 from "./home/deal-filter.js";
import reportFilter from "./settle/settle-filter.js";
import messageFilter from "./message/message-filter.js";
import commonFilter from './common_filter.js';

export default{
	...dealFilter,
	...dealFilter2,
	...reportFilter,
	...messageFilter,
    ...commonFilter
}
