import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
import download from '@xy/download'

// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    let headers = {}
    headers = Object.assign({}, { 'x-requested-with': true }, config.headers)
    config.headers = headers

    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {

    const res = response.data
    // // if the custom code is not 20000, it is judged as an error.
    if (res.code != 200) {

      //   if (res.code == '405') {// 无权访问
      if (response.config.autoDealError) {
        Message.error(res.msg||"系统错误")
        return Promise.reject(res)
      }


      // return response.data.data
      // } else {
      //   return response
    }
    if(response.config.noAutoDealData){
      return response.data
    }
    return response.data.data
  },
  error => {
    Message({
      message: error.msg,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export let get = (url, data = {}, options = {}) => {
  const opt = { autoDealError: true, ...options }
  return service({
    url: url,
    method: 'get',
    params: { ...(data.params || data), t: +new Date() },
    ...opt
  })
}

export let post = (url, data, options = {}) => {
  const opt = { autoDealError: true, ...options }
  return service({
    url: url,
    method: 'post',
    data,
    ...opt
  })
}

 export  let put = (url,data, options = {})=>{
  const opt = {autoDealError: true, ...options}
  return service({
     url: url ,
     method: 'put',
     data,
     ...opt
 })
}


export let downloadFile = (url, data, ops = {}) => {
  ops.headers = ops.headers || {}
  ops.headers['x-requested-with'] = true
  // ops.headers['xybbc_token'] =  getToken()
  return download(process.env.VUE_APP_BASE_API + url, data, ops)
}

service.post = post
service.get = get

export default service
