import { post , get , put} from '@/utils/request'
export const purchaseQueryList = (data) => {
    return post(`/supplier/orderManagement/purchaseOrder/query`,data)
}
export const purchaseQueryDetailByPurchaseNo = (data) => {
    return post(`/supplier/orderManagement/purchaseOrder/queryDetailByPurchaseNo`,
    null,{params:data})
}

export const querySkuListBySupplierOrderId = (data) => {
    return post(`/supplier/orderManagement/purchaseOrder/querySkuListBySupplierOrderId`,null,{
        params:data
    })
}
export const cancelTransport = (data) => {
    return post(`/supplier/orderManagement/purchaseOrder/cancelTransport`,null,{
        params:data
    })
}
export const supplierTransportCreate = (data) => {
    return post(`/supplier/transport/create`,data)
}
export const queryTransportDetailList = (data) => {
    return post(`/supplier/transport/viewTransportDetailList`,null,{
        params:data
    })
}
export const updateLogistics = (data) => {
    return post(`/supplier/transport/updateLogistics`,data)
}
export const queryExportColumnList = (data) => {
    return post(`/supplier/apiManagement/purchaseOrderApi/queryExportColumnList`,data)
}
export const fisButtonDisplayed = (data) => {
    return post(`/supplier/orderManagement/purchaseOrder/fisButtonDisplayed`,data)
}
export const queryStatusCount = (data) => {
    return post(`/supplier/orderManagement/purchaseOrder/queryStatusCount`,data)
}
export const purchaseOrderApiExportAll = (data) => {
    return post(`/supplier/apiManagement/purchaseOrderApi/exportAll`,data)
}
export const cancelTransportList = (data) => {
    return post(`/supplier/orderManagement/purchaseOrder/cancelTransportList`,{},{
        params:data
    })
}
export const taskList = (data) => {
    return post(`/supplier/transport/taskList`,data)
}
