const Main = resolve => require(['@/views/main.vue'], resolve)

/*首页模块路由*/
export default {
  path: '/supplier/home',
  component: Main,
  children: [{
      path: 'index',
      component: resolve => require(['@/views/home/index.vue'], resolve),
      children: [{
        path: '',
        component: resolve => require(['@/views/home/components/home-index.vue'], resolve)
      }]
    },
    /*交易管理路由*/
    {
      path: 'deal',
      component: resolve => require(['@/views/home/index.vue'], resolve),
      /*交易管理嵌套子路由*/
      children: [{
          path: 'purchase/order-list',
          component: resolve => require(['@/views/home/deal/purchase/order-list.vue'], resolve)
        },
        {
          path: 'api/order-list',
          component: resolve => require(['@/views/home/deal/api/order-list.vue'], resolve)
        },
        {
          path: 'common/order-detail',
          component: resolve => require(['@/views/home/deal/common/order-detail.vue'], resolve)
        },
        {
          path: 'purchase/order-detail',
          component: resolve => require(['@/views/home/deal/purchase/order-detail.vue'], resolve)
        },
        {
          path: 'purchase/submit-waybill',
          component: resolve => require(['@/views/home/deal/purchase/submit-waybill.vue'], resolve)
        },
        {
          path: 'overDueOrder',
          component: resolve => require(['@/views/home/deal/overdue-order.vue'], resolve)
        },
        {
          path: 'deliveryOrder',
          component: resolve => require(['@/views/home/deal/delivery/order.vue'], resolve)
        },
        {
          path: 'deliveryOrderDetail',
          component: resolve => require(['@/views/home/deal/delivery/order-detail.vue'], resolve)
        },
        {
          path:'aftersalesOrder',
          component:resolve =>require(['@/views/home/deal/aftersales/order.vue'],resolve)
        },
        {
          path:'aftersalesOrder/detail',
          component:resolve =>require(['@/views/home/deal/aftersales/detail.vue'],resolve)
        },
        {
          path:'aftersalesWorkOrder',
          component:resolve =>require(['@/views/home/deal/aftersales/work-order.vue'],resolve)
        },
        {
          path:'aftersalesWorkOrder/detail',
          component:resolve =>require(['@/views/home/deal/aftersales/work-detail.vue'],resolve)
        },
        {
          path: 'adjustmentOrder',
          component: resolve => require(['@/views/home/deal/adjustment-order.vue'], resolve)
        },
        {
          path: 'supplementaryWorkOrder',
          component: resolve => require(['@/views/home/deal/supplementary-work-order.vue'], resolve)
        }
      ]
    },
    /*商品管理*/
    {
      path: 'goodsManagement',
      component: resolve => require(['@/views/home/index.vue'], resolve),
      children: [{
          path: 'publishGoods/addSupply',
          component: resolve => require(['@/views/home/goods_management/add_supply.vue'], resolve),
        },
        {
          path: 'publishGoods/lookSupply',
          component: resolve => require(['@/views/home/goods_management/look_supply.vue'], resolve),
        },
        {
          path: 'publishGoods/editorSupply',
          component: resolve => require(['@/views/home/goods_management/editor_supply.vue'], resolve),
        },
        {
          path: 'publishGoods/updateSupply',
          component: resolve => require(['@/views/home/goods_management/update_supply.vue'], resolve),
        },
        {
          path: 'publishGoods',
          component: resolve => require(['@/views/home/goods_management/publish_goods.vue'], resolve),
        },
        {
          path: 'supplyList',
          component: resolve => require(['@/views/home/goods_management/supply_list.vue'], resolve),
        },
        {
          path: 'supplyList/details',
          component: resolve => require(['@/views/home/goods_management/supply_list_details.vue'], resolve),
        },
        {
          path: 'publishGoods/goodsInfo',
          component: resolve => require(['@/views/home/goods_management/goods_information.vue'], resolve),
        },
        // {
        //   path: 'draftList',
        //   component: resolve => require(['@/views/home/goods_management/draft_goods_list.vue'], resolve)
        // },
        {
          path: 'storageList',
          component: resolve => require(['@/views/home/goods_management/storage_goods_list.vue'], resolve)
        }
      ]
    },
    /*运费管理*/
    {
      path: 'freight',
      component: resolve => require(['@/views/home/index.vue'], resolve),
      children: [{
          path: 'freightTemplate',
          component: resolve => require(['@/views/home/freight/freight-template.vue'], resolve)
        },
        {
          path: 'createTemplate',
          component: resolve => require(['@/views/home/freight/createTemplate.vue'], resolve)
        },
        //运费模板查看详情
        {
          path: 'lookTemplate',
          component: resolve => require(['@/views/home/freight/lookTemplate.vue'], resolve)
        }
      ]
    },
    /*地址管理路由*/
    {
      path: 'address',
      component: resolve => require(['@/views/home/index.vue'], resolve),
      /*交易管理嵌套子路由*/
      children: [{
          path: 'deliveryAddress',
          component: resolve => require(['@/views/home/address/delivery-address.vue'], resolve)
        },
        {
          path: 'aftersalesAddress',
          component: resolve => require(['@/views/home/address/aftersales-address.vue'], resolve)
        },
      ]
    },
    /*结算管理*/
    {
      path: 'settle',
      name: '/supplier/home/settle',
      component: resolve => require(['@/views/home/index.vue'], resolve),
      children: [{
          path: 'substitute',
          name:'substitute',
          component: resolve => require(['@/views/home/settle_management/substitute.vue'], resolve)
        },
        // 结算单详情
        {
          path: 'substitute/settleDetail',
          name:'substitute/settleDetail',
          component: resolve => require(['@/views/home/settle_management/settle_order_detail.vue'], resolve),
          meta: { active: 'substitute' }
        },
        {
          path: 'substitute/settleDetailCount',
          name:'substitute/settleDetailCount',
          component: resolve => require(['@/views/home/settle_management/settle_order_detail_count.vue'], resolve),
          meta: { active: 'substitute' }
        },
        {
          name:'backOrderList',
          path: 'backOrderList',
          component: resolve => require(['@/views/home/settle_management/back_money.vue'], resolve)
        },
        //回款单详情
        {
          name: 'backOrderList/backDetail',
          path: 'backOrderList/backDetail',
          component: resolve => require(['@/views/home/settle_management/back_detail.vue'], resolve),
          meta: { active: 'backOrderList' }
        }
      ]
    },
    /*商品映射管理*/
    {
      path: 'commodity',
      component: resolve => require(['@/views/home/index.vue'], resolve),
      children: [{
          path: 'commodityRelation',
          name: 'commodityRelation',
          component: resolve => require(['@/views/home/commodity/commodityRelation.vue'], resolve)
        }

      ]
    },
    /*账号管理*/
    {
      path: 'account',
      component: resolve => require(['@/views/home/index.vue'], resolve),
      children: [{
          path: 'accountInfo',
          component: resolve => require(['@/views/home/account/account_info.vue'], resolve)
        },
        {
          path: 'accountMoney',
          component: resolve => require(['@/views/home/account/account_money.vue'], resolve)
        },
        // 线下回款单
        {
          path: 'underlineBackMoney',
          component: resolve => require(['@/views/home/account/underline_back.vue'], resolve)
        },
        {
          path: 'mailSetting',
          component: resolve => require(['@/views/home/account/mail_setting.vue'], resolve)
        },
        {
          path: 'updatePsw',
          component: resolve => require(['@/views/home/account/update_psw.vue'], resolve)
        }
      ]
    }
  ]
}
