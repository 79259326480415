const config = {
    nodeIpPort: {
        port: '3001',
    },
    javaIpPort: {
        ip: 'xybbc-gateway-service',
        port: '8573',
        prefix: '',
    },
    redis: {
        host: "10.10.10.102",
        port: "16379",
        password: "HU5MdAD3iX59",
    },
    mockIpPort: {
        ip: '192.168.0.246',
        port: '3000',
    },
    javaImgIpPort: {
        ip: '127.0.0.1',
        port: '3000',
    },
    imgBasicUrl: 'https://img0.bbc.xyb2b.com/',
    aesKey: '2015xy0505QQHbbc',
}
        
module.exports = {
    nodeIpPort: config.nodeIpPort,
    nodeUrl: `http://${config.nodeIpPort.ip}:${config.nodeIpPort.port}`,
    permitOnlyUrl: `http://${config.mockIpPort.ip}:${config.mockIpPort.port}`,
    javaUrl: `http://${config.javaIpPort.ip}:${config.javaIpPort.port}`,
    mockIpPort: `http://${config.mockIpPort.ip}:${config.mockIpPort.port}`,
    javaImgUrl: `http://${config.javaImgIpPort.ip}:${config.javaImgIpPort.port}`,
    imgBasicUrl: config.imgBasicUrl,
    aesKey: config.aesKey,
    redis: config.redis,
}
