//行云规则路由

export default {
  path: '/supplier',
  component: resolve => require(['@/views/main.vue'], resolve),
  children: [
    {
      path: 'regulation',
      component: resolve => require(['@/views/regulation/index.vue'], resolve),
      children:[
        {
          path:'supplierAgreement',
          component:resolve=>require(['@/views/regulation/supplier_agreement.vue'],resolve)
        },
        {
          path:'consumerProtection',
          component:resolve=>require(['@/views/regulation/consumer_protection.vue'],resolve)
        },
        {
          path:'goodsAgreement',
          component:resolve=>require(['@/views/regulation/goods_agreement.vue'],resolve)
        },
        {
          path:'certificates',
          component:resolve=>require(['@/views/regulation/certificates.vue'],resolve)
        }
      ]
    }
  ]
}
