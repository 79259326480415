
import { post , get , put} from '@/utils/request'
// 直邮区
export const getDirectMailNameList = (data) => {
    return get(`/supplier/deliveryaddress/getDirectMailNameList`,data)
}
//直邮服务商
export const getDirectServerList = (data) => {
    return get(`/supplier/deliveryaddress/getDirectServerList`,data)
}

export const getSupplierWarehouseList = (data) => {
    return get(`/supplier/deliveryaddress/getSupplierWarehouseList`,data)
}
// 查询物流轨迹
export function queryTrack(data) {
    return post( `/supplier/order/afterSaleOrder/queryTrack`, data)
}

