import  deal  from './deal/index'  
import  address  from './address/index'  
// import  fin  from './finance/index'
// import  ope  from './operation/index'
// import  jur  from './jurisdiction/index'
// import  cha from './channelDesk/index'

//暴露所有模块的api
export default  {
    deal,
    address
    // fin,
    // ope,
    // jur,
    // cha
}
