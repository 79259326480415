const VERSION = 'feature-1.0.0';

const config = require('../../../server/config');

let BUILD_PASS = 'prod';
if (config.javaUrl.includes('dev')) {
  BUILD_PASS = 'dev';
} else if (config.javaUrl.includes('test')) {
  BUILD_PASS = 'test';
} else if (config.javaUrl.includes('fix')) {
  BUILD_PASS = 'fix';
}
// 版本号
const RELEASE = `b2csuppliers-${BUILD_PASS || 'noenv'}-${VERSION}`;
// 生产环境判断
const IS_PRODUCTION = BUILD_PASS === 'prod';
// 是否需要上报
const NEED_REPORT = ['dev','test', 'fix', 'prod'].includes(BUILD_PASS); // BUILD_PASS=='prod';

const DSN = IS_PRODUCTION
  ? 'https://1381cd5f2b814160a98d1c540e31c909@sentry.xyb2b.com/51'
  : 'http://f67a46bd81c942589877e92874ef81d2@192.168.0.94:9000/23';
// sentry上创建的项目名
const PROJECT_NAME = 'b2csuppliers';

module.exports = {
  VERSION,
  RELEASE,
  IS_PRODUCTION,
  NEED_REPORT,
  DSN,
  PROJECT_NAME,
};
