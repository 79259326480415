import * as types from './mutation_types.js';

export default {
  [types.GET_USER_INFO](state, info) {
    state.userInfo = info;
  },
  [types.GET_LOGIN_STATE](state, sign) {
    state.logined = sign;
  },
  [types.TITLE](state,obj){
    state.title=obj;
  },

  [types.NOTICE_DATA](state,msgData){
  	state.noticeData=msgData;
  },

  /*搜索框*/
  [types.SEARCH_TYPE](state,searchType){
    state.searchType=searchType;
  },
  [types.SEARCH_VAL](state,searchVal){
    state.searchVal=searchVal;
  }
}
