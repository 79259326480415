/**
 * 公共下拉框数据
 */

const common = {}

// 订单状态
common.orderStatus = [
    { value: 0, label: '全部' },
    { value: 4, label: '待发货' },
    { value: 5, label: '待收货' },
    { value: 6, label: '已收货' },
    { value: 7, label: '已完成' },
    { value: 8, label: '已取消' }
]

common.overOrderStatus = [
    { value: 2, label: '部分发货' },
    { value: 1, label: '待发货' },
]

// 公共列表底部每页条数数据数组
common.pageSizes = [20, 50, 100]
common.pageSizesMiddle = [20, 50, 100,200]

// 公共列表底部当前每页条数,默认20
common.pageSize = 20

export default common
