import { post , get , put} from '@/utils/request'
// 交易管理售后详情
export const afterDetail = (data) => {
    return get(`/supplier/order/afterSaleOrder/detail`,data)
}
export const queryLogistics = (data) => {
    console.log('fdsafasd', data)
    return post(`/supplier/order/afterSaleOrder/queryLogistics`,data)
}
// 订单中心审核
export const check = (data) => {
    return put(`/supplier/order/afterSaleOrder/check`,data)
}
export const queryAftersaleStatusCount = (data) => {
    return get(`/supplier/tradeManagement/orderAftersale/queryStatusCount`,data)
}