const orderStatus = function (status) {
  switch (status) {
    case 0:
      return "待发货";
      break;
    case 1:
      return "待确认收货";
      break;
    case 2:
      return "已完成";
      break;
    case 3:
      return "已关闭";
      break;
    case 4:
      return "已收货退货";
      break;
    case 5:
      return "未及时发货";
      break;
    case 6:
      return "商品违规";
      break;
    case 7:
      return "备货中";
      break;
    default:
      return "";
      break;
  }
}
const pushStatus = function (status) {
  switch (status) {
    case 0:
      return "未推送";
      break;
    case 1:
      return "已推送";
      break;
    case 2:
      return "订单新建";
      break;
    case 3:
      return "订单审核";
      break;
    case 4:
      return "订单待分配";
      break;
    case 5:
      return "分配完成";
      break;
    case 6:
      return "装箱完成";
      break;
    case 7:
      return "订单完成";
      break;
    case 8:
      return "库存不足";
      break;
    case 9:
      return "国检审核未过";
      break;
    case 10:
      return "海关单证审核未过";
      break;
    case 11:
      return "海关查验未过";
      break;
    case 12:
      return "关贸审核失败";
      break;
    case 13:
      return "平台审核失败";
      break;
    case 14:
      return "运单审核失败";
      break;
    case 15:
      return "支付单审核失败";
      break;
    case 16:
      return "订单关闭";
      break;
    case 17:
      return "身份认证失败";
      break;
    case 18:
      return "申报支付单失败";
      break;
    default:
      return "";
      break;
  }
}
const goodsSource = function (source) {
  switch (source) {
    case 1:
      return "供应商代发";
      break;
    case 2:
      return "供应商锁库";
      break;
    default:
      return "未设置";
      break;
  }
}
export const deliveryStatus = function (source) {

  switch (source) {
    case 1:
      return "待发货";
      break;
    case 2:
      return "待收货";
      break;
    case 3:
      return "已签收";
      break;
    case 4:
      return "已完成";
      break;

  }
}

const settleStatus = function (status) {
  switch (status) {
    case 0:
      return "未结算";
      break;
    case 1:
      return "已结算";
      break;
    default:
      return "";
      break;
  }
}
const returnRefund = function (status) {
  switch (status) {
    case 0:
      return "无";
      break;
    case 1:
      return "等待审核";
      break;
    case 2:
      return "等待寄回";
      break;
    case 3:
      return "等待收货";
      break;
    case 4:
      return "退货成功";
      break;
    case 5:
      return "退货关闭";
      break;
    default:
      return "";
      break;

  }
}
// 发货单状态
const dispatchOrderStatus = function (status) {
  switch (status) {
    case 0:
      return "全部";
      break;
    case 1:
      return "待发货";
      break;
    case 2:
      return "待收货";
      break;
    case 3:
      return "已收货";
      break;
    case 4:
      return "已完成";
      break;
    default:
      return "";
      break;

  }
}

const dateFormat = function (s, format) {
  var date = new Date(s);
  var map = {
    "M": date.getMonth() + 1, //月份
    "d": date.getDate(), //日
    "h": date.getHours(), //小时
    "m": date.getMinutes(), //分
    "s": date.getSeconds(), //秒
    "q": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  format = format.replace(/([yMdhmsqS])+/g, function (all, t) {
    var v = map[t];
    if (v !== undefined) {
      if (all.length > 1) {
        v = '0' + v;
        v = v.substr(v.length - 2);
      }
      return v;
    } else if (t === 'y') {
      return (date.getFullYear() + '').substr(4 - all.length);
    }
    return all;
  })
  return s ? format : '';
}

const recentWeekDate = function (num) {
  var date = new Date();
  var nowMilliseconds = date.getTime();
  var recentMilliseconds = num * 24 * 60 * 60 * 1000;
  var recentDate = new Date(nowMilliseconds - recentMilliseconds);
  return dateFormat(recentDate, "yyyy-MM-dd");
}
// --------------------订单状态 start----------------------
const purchaseOrderStatus = function (status) {
  switch (status) {
    case 1:
      return "待发货";
      break;
    case 2:
      return "部分发货";
      break;
    case 3:
      return "待收货";
      break;
    case 4:
      return "已收货";
      break;
    case 5:
      return "已完成";
      break;
    case 6:
      return "已取消";
      break;
    default:
      return "";
      break;
  }
}
/**
 * 新采购单状态
 * @param {*} status 
 */
const purchaseOrderNewStatus = function (status) {
  // 采购单状态:1待确认 2已确认 3 已完成 4已取消 5已关闭 ,
  switch (status) {
    case 1:
      return "待确认";
    case 2:
      return "待发货";
    case 3:
      return "部分发货";
    case 4:
      return "已发货";
    case 5:
      return "已完成";
    case 6:
      return "已关闭";
    case 7:
      return "已取消";
    case 8:
        return "低税重推";
  }

}
// 售后状态
const afterSaleStatus = function (status) {
  switch (status) {
    case 1:
      return "售后中";
      break;
    case 2:
      return "售后完成";
      break;
    default:
      return "/";
      break;
  }
}
const faftersaleType = function (status) {
  switch (status) {
    case 1:
      return "退款";
      break;
    case 2:
      return "退款退货";
      break;
    case 3:
      return "补偿";
      break;
    default:
      break;
  }
}
// 左：运营中台状态，右：供应商状态）待商审-待处理、待退货-待退货、待退款-已退货、待财审-待财审、已成功-已完成、已拒绝-已拒绝
const faftersaleStatus = function (status) {
  switch (status) {
    case 1:
      return "待客审";
      break;
    case 2:
      return "待采审";
      break;
    case 3:
      return "待处理";
      break;
    case 4:
      return "待财审";
      break;
    case 5:
      return "已拒绝";
      break;
    case 6:
    case 7:
      return "待收货";

      break;
    case 8:
      return "已完成";
      break;
    default:
      return "/";
      break;
  }
}
// 采购订单详情 售后状态
const purchaseAaftersaleStatus = function (status) {
  switch (status) {
    case 3:
      return "待处理";
      break;
    case 6:
      return "待退货";
      break;
    case 7:
      return "已退货";
      break;
    case 4:
      return "待财审";
      break;
    case 12:
      return "已拒绝";
      break;
    case 8:
      return "已完成";
      break;
    default:
      return "/";
      break;
  }
}

// 贸易类型
const tradeTypeStatus = function (status) {
  switch (status) {
    case 1:
      return '国内贸易';
      break;
    case 2:
      return '完税进口';
      break;
    case 3:
      return '跨境保税';
      break;
    case 4:
      return '海外直邮';
      break;
    case 5:
      return '售后商品';
      break;
    case -1:
      return '未知';
      break;
    default:
      break;
  }
}
// 发货单状态 
const ftransportOrderStatus = function (status) {
  switch (status) {
    case 1:
      return "待发货";
      break;
    case 2:
      return "待收货";
      break;
    case 3:
      return "已收货";
      break;
    case 4:
      return "已完成";
      break;
    default:
      return "--";
      break;
  }
}
//  订单状态
const forderStatus = function (status) {
  switch (status) {
    case 1:
      return "待支付";
      break;
    case 2:
      return "待确认";
      break;
    case 3:
      return "待推送";
      break;
    case 4:
      return "待发货";
      break;
    case 5:
      return "待收货";
      break;
    case 6:
      return "已收货";
      break;
    case 7:
      return "已完成";
      break;
    case 8:
      return "已取消";
      break;
    default:
      return "--"
      break;
  }
}
// 支付方式
const forderPayType = function (status) {
  switch (status) {
    case 1:
      return '余额';
      break;
    case 2:
      return '支付宝';
      break;
    case 3:
      return '微信';
      break;
    case 4:
      return '信用';
      break;
    case 5:
      return '混合支付';
      break;
    default:
      break;
  }
}
// 逾期状态
const foverdueStatus = function (status) {
  switch (status) {
    case 1:
      return '即将逾期';
      break;
    case 2:
      return '已逾期';
      break;
    default:
      return "-"
      break;
  }
}

// 售后原因
const fapplyReason = function (status) {
  switch (status) {
    case 1:
      return '破损补偿';
      break;
    case 2:
      return '运费补偿';
      break;
    case 3:
      return '延迟发货补偿';
      break;
    case 4:
      return '采购价格调整';
      break;
    case 5:
      return '供应商罚款';
      break;
    case 6:
      return '运费调整';
      break;
    case 7:
      return '技术服务费';
      break;
    case 8:
      return '其他原因 ';
      break;
    default:
      break;
  }
}
// api列表 推送状态
const pushResultStatus = function (status) {
  switch (status) {
    case 0:
      return "推送失败-供应商、服务商";
      break;
    case 1:
      return "推送失败-供应商";
      break;
    case 2:
      return "推送失败-服务商";
      break;
    default:
      return "/"
      break;
  }
}
// api列表 订单状态
const apiOrderStatus = function (status) {
  switch (status) {
    case 1:
      return "待发货";
      break;
    case 2:
      return "部分发货";
      break;
    case 3:
      return "待收货";
      break;
    case 4:
      return "已收货";
      break;
    case 5:
      return "已完成";
      break;
    case 6:
      return "已取消";
      break;
    default:
      return "/"
      break;
  }
}

// --------------------订单状态 end----------------------
export default {
  orderStatus,
  pushStatus,
  goodsSource,
  settleStatus,
  dateFormat,
  recentWeekDate,
  returnRefund,
  dispatchOrderStatus,
  purchaseOrderStatus,
  purchaseOrderNewStatus,
  afterSaleStatus,
  faftersaleType,
  faftersaleStatus,
  purchaseAaftersaleStatus,
  ftransportOrderStatus,
  forderStatus,
  forderPayType,
  tradeTypeStatus,
  foverdueStatus,
  fapplyReason,
  pushResultStatus,
  apiOrderStatus
}
