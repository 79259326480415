import  * as  types from "./mutation_types.js";
import Vue from 'vue';
const getNotice=({commit}) =>{
   // Vue.prototype.$http.post("/supplier/message/queryMsgList",{
   //            msgType:3,
   //            msgChildType :' ',
   //            pageIndex:1,
   //            pageSize:1
   //  }).then(res =>{
   //  	let data=res.data;
   //  	if(data.retCode==200){
   //  		if(data.retEntity.msgList.length==1){
   //
   //  			commit(types.NOTICE_DATA, data.retEntity.msgList[0]);
   //  		}
   //  	}
   //  })
}

export default{
	getNotice
}
