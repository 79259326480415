import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters.js';
import mutations from './mutations.js';
import actions from './actions.js';

Vue.use(Vuex);

const state={
  userInfo:{},
  logined:null,
  title:{},
  noticeData:{},
  // 全局的搜索框 type和val
  searchVal:'',
  searchType:'',
};

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  getters,
  mutations,
  actions
})
