/*登录分路由*/
export default {
  path: '/account',
  component: resolve => require(['@/views/main.vue'], resolve),
  children: [
    {
      path: '',
      component: resolve => require(['@/views/account/index.vue'], resolve)
    }
  ]
}
