import Vue from 'vue';
import VueRouter from "vue-router";
import Element from 'element-ui';

import axios from 'axios';
import App from './App';
import routes from './router/index.js';
import store from './store/index.js';
// import {
//   sync
// } from 'vuex-router-sync';
import {
  imgBasicUrl,
  // nodeUrl,
  // javaUrl
} from '@@server/config';
import commonFn from '@/assets/javascripts/common_function.js';
import filter from "@/filters/index.js";
import './assets/javascripts/validate.js';
/*全部的样式设置 以及element-ui font-awesome*/
import 'element-ui/lib/theme-chalk/index.css';
import './assets/styles/common.scss';
import "font-awesome/css/font-awesome.css";
import common from './common'
import loading from './components/loading/index.js'
import sentryVue from "@xy/sentry-vue";
const { RELEASE, NEED_REPORT, DSN } = require("./config");

import api from '@/api/index'
Vue.prototype.$api = api


Vue.prototype.$isHost = function(name){
 
 return location.hostname.includes(name)

}

Vue.config.productionTip = false;
Vue.use(Element, {
  size: 'small'
})
Vue.use(Element);

Vue.prototype.$load = loading
Vue.prototype.imgBasicUrl = imgBasicUrl
Vue.prototype.$imgBasicUrl = imgBasicUrl

/*http请求 axios配置*/
axios.defaults.withCredentials = true;
// console.log('nodeIpPort',nodeUrl)
// axios.defaults.baseURL = nodeUrl;
axios.defaults.baseURL = process.env.VUE_APP_BASE_API

// axios.defaults.javaBasicUrl = 'http://' + javaUrl;
axios.defaults.timeout = 30000
Vue.prototype.$http = axios;

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);
/*route 路由配置*/
const router = new VueRouter({
  mode: "history",
  routes
})
Vue.prototype.$newWindow = function (url, config = {}) {
  let { query = {} } = config
  window.open(`${url}?${Object.keys(query).map(key => `${key}=${query[key]}`).join('&')}`)
}
//是否显示日志
let c_showLog = process.env.NODE_ENV != 'production';
window.log = c_showLog && console.log.bind(this) || (() => { });
if(Vue.prototype.$isHost('hzjmyx')){
  document.title=`供应商中心-嘉明全球汇-进口货源，无忧分销！`
}else{
  document.title=`供应商中心-行云全球汇-进口货源，无忧分销！`
}
// window.$sysName = Vue.prototype.$isHost('hzjmyx')?'嘉明':'行云'
Vue.prototype.$sysName =()=>{
  return  Vue.prototype.$isHost('hzjmyx')?'嘉明':'行云'
}
// 

// sync(store, router);
router.beforeEach((route, redirect, next) => {
  if ((!commonFn.getCookie('newSupplierCookie') || !commonFn.getCookie('isLogin')) && route.path !== '/login' && route.path !== '/register' && route.path != '/login/forgetPassword' && route.path != '/supplier/enterData') {
    next({
      path: '/login',
      query: {
        redirect: route.fullPath
      }
    });
  } else {
    next();
  }
});
// vue原型挂载 - 公共数据
Vue.prototype.$common = common
Vue.prototype.$user = function (key) {
  // {
  //   cooperationType:localStorage.getItem('cooperationType')
  // }
  return localStorage.getItem(key)
}
axios.interceptors.response.use(response => {
  if (response.data && response.data.retCode) {
    if (response.data.retCode == 1401) {
      commonFn.logout();
      router.replace({
        path: '/login',
        query: {
          redirect: router.currentRoute.fullPath
        }
      });
    }
  }
  return response;
},
  error => {
    return Promise.reject(error);
  });

Object.keys(filter).forEach(key => {
  Vue.filter(key, filter[key])
})

const id = localStorage.getItem('userId'),name = localStorage.getItem('username')

// 注册sentry
if(NEED_REPORT) {
  sentryVue.init({
    dsn: DSN,
    // 传入Vue中的router
    routes: router,
    responseErrorHandle: false,
    release: RELEASE,
    configureScope: (scope) => {
      scope.setUser({id, name})
    }
  });
}


new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})