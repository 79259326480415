import * as purchase from './purchase'
import * as after from './after'
import * as delivery from './delivery'
import * as home from './home'

import  mergeCheck from '../mergeCheck'
export default mergeCheck([
    purchase,
    delivery,
    after,
    home,
])
