export default {
  path: '*',
  component: resolve => require(['@/views/main.vue'], resolve),
  children: [
    {
      path: '',
      component: resolve => require(['@/views/error/404.vue'], resolve)
    }
  ]
}
