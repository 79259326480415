const messageType=function(type){
	switch(type){
		case 0:
		  return "订单消息";
		  break;
		case 1:
		  return "商品消息";
		  break;
		case 2:
		  return "结算消息";
		  break;
		case 3:
		  return "系统消息/公告";
		  break;
		case 4:
		  return "招投标信息";
		  break;
		default:
		  return "";
		  break;
	}
}

export default{
	messageType
}