export default {
  setCookie: function (name, value, expire) {
    if (expire) {
      window.document.cookie = name + "=" + escape(value) + ";path=/;max-age=" + expire;
    } else {
      window.document.cookie = name + "=" + escape(value) + ";path=/;";
    }
  },
  getCookie: function (name) {
    let matchResult = unescape(window.document.cookie).match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return matchResult ? matchResult[2] : null;
  },
  deleteCookie: function (name) {
    this.setCookie(name, '', 0);
  },
  logout(cb) {
    window.localStorage.removeItem('userId')
    window.localStorage.removeItem('userName')
    this.deleteCookie('newSupplierCookie');
    this.deleteCookie('isLogin');
    if (cb) {
      cb()
    }
  },
  selectAll() {
    let selectAllEle = document.getElementById('selectAll');
    let checkboxs = document.getElementsByClassName('checkbox-item');
    let state = selectAllEle.checked;
    let len = checkboxs.length;
    for (let i = 0; i < len; i++) {
      checkboxs[i].checked = state;
    }
  },
  // 取消全选
  cancelSelectAll() {
    let selectAllEle = document.getElementById('selectAll');
    let checkboxs = document.getElementsByClassName('checkbox-item');
    let len = checkboxs.length;
    for (let i = 0; i < len; i++) {
      checkboxs[i].checked = false;
    }
    selectAllEle.checked = false
  },
  changeSelectStatus() {
    let selectAllEle = document.getElementById('selectAll');
    let checkboxs = document.getElementsByClassName('checkbox-item');
    let len = checkboxs.length;
    let checkedArr = [];
    for (let i = 0; i < len; i++) {
      if (checkboxs[i].checked) {
        checkedArr.push(checkboxs[i]);
      }
    }
    if (checkedArr.length == len) {
      selectAllEle.checked = true;
    } else {
      selectAllEle.checked = false;
    }
  },
  // 导出时 转化参数（get方法的）
  serialization(param) {
    let params = "";
    for (let key in param) {
      if (!param[key]) {
        continue;
      }
      params !== "" && (params += "&");
      params += key + "=" + param[key];
    }
    return params
  },
}
