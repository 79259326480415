//正在招商模块路由

export default{
  path: '/supplier/attractInvestment',
  component: resolve=>require(['@/views/main.vue'],resolve),
  children:[
    {path:'index',component:resolve=>require(['@/views/attract_investment/index.vue'],resolve)}
  ]
}

