const dict = {
  // 结算周期
  fsettlementPaymentType: {
    0: '不生成账单',
    1: '日结',
    2: '10日结',
    3: '周结',
    4: '半月结',
    5: '月结'
  },
  // 结算方式
  fclearingForm: {
    1: '代发按周期结算',
    2: '大贷先货后款',
    3: '大贷先款后贷'
  },
  // 发票类型 
  finvoiceType: {
    0: '无',
    1: '形式发票',
    2: '增值税发票',
    3: '普通发票'
  }
}

export default dict