export default function(list){
    let map = {};
    let obj = {}
    for(let i=0;i<list.length;i++){
        let item = list[i]
        Object.keys(item).forEach(key=>{
            if(map[key]){
                alert('存在重复的接口'+key)
                console.error('存在重复的接口-------------------',key)
            }
            obj[key] = item[key]
            map[key] = true
            // console.log('list-------------------',key)
        })
    }
  return obj
}