import Vue from 'vue';
import VeeValidate, {Validator} from 'vee-validate';
import zh from 'vee-validate/dist/locale/zh_CN';
import axios from 'axios';

Validator.addLocale(zh);

const validatorConfig = {
  locale: 'zh_CN',
  fieldsBagName: 'fieldBags',
  events: 'input'
};

Vue.use(VeeValidate, validatorConfig);

const dictionary = {
  zh_CN: {
    messages: {
      email: () => '请输入正确的邮箱格式',
      required: (field) => field,
      confirmed: () => '两次输入的密码不一致'
    },
    attributes: {
      email: '请输入邮箱',
      password: '请输入密码',
      confirmPwd: '请输入密码',
      authCode: '请输入验证码',
      phone: '请输入手机号',
      name: '请输入姓名',
      remark: '请输入备注',
      receive: '请选择是否接收'
    }
  }
};

Validator.updateDictionary(dictionary);

Validator.extend('password', {
  messages: {
    zh_CN: '密码只能包含字母、数字和_@!#$%^&*.~+'
  },
  validate: value => {
    return /^[\w\@\!\#\$\%\^\&\*\.\~\+]+$/.test(value);
  }
});

Validator.extend('phone', {
  messages: {
    zh_CN: '请输入正确的手机号码'
  },
  validate: value => {
    return value.length == 11 && /^((13|14|15|17|18)[0-9]{1}\d{8})$/.test(value);
  }
});

Validator.extend('name', {
  messages: {
    zh_CN: '请输入正确的姓名'
  },
  validate: value => {
    return /^[\u4E00-\u9FA5]{1,6}$/.test(value) || /^[A-Za-z]+$/.test(value);
  }
});

Validator.extend('existsEmail', {
  messages: {
    zh_CN: '邮箱已经被注册！'
  },
  validate: value => {
    return axios.post('/supplier/register/queryExistsEmail', {username: value}).then(res => {
      return !res.data.data;
    })
  }
});
