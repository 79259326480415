//域名指向首页
export default {
  path: '/',
  component: resolve => require(['@/views/main.vue'], resolve),
  children: [
    {
      path: '',
      component: resolve => require(['@/views/home/index.vue'], resolve),
      children: [
        {
          path: '',
          component: resolve => require(['@/views/home/components/home-index.vue'], resolve)
        }
      ]
    }
  ]
}
