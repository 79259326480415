export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_LOGIN_STATE = 'GET_LOGIN_STATE';
export const TITLE='TITLE';

/*系统消息、公告*/
export const NOTICE_DATA="NOTICE_DATA";

/*头部搜索框 searchType*/
export const SEARCH_TYPE="SEARCH_TYPE";

/*头部搜索框 searchVal*/
export const SEARCH_VAL="SEARCH_VAL";
