const _report_settleStatus=function(status){
    switch(status){
    	case 0:
            return  "未结算";
            break;
        case 1:
             return "已结算";
             break;
        case 2:
             return "结算中";
             break;
         default:
             return "";
             break;
    }
}
const billType=function(type){
    switch(type){
        case 1:
            return  "推送订单";
            break;
        case 2:
             return "退货订单";
             break;
        case 3:
             return "取消订单";
             break;
        case 4:
             return "重新推送";
             break;
        case 5:
            return "调整工单";
            break;
        case 7:
             return "售后工单";
             break;
         default:
             return "异常";
             break;
    }
}
export default{
	_report_settleStatus,
    billType
}
