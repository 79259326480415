import Vue from 'vue'
let isLoading
function open() {

    const loading = Vue.prototype.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    clearTimeout(isLoading)
    isLoading = setTimeout(() => {
        loading.close();
    }, 10000);
}

function close() {
    const loading = Vue.prototype.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
    });
    loading.close();
}
// 暴露出这些属性和方法
export default {
    open,
    close
}
