import {imgBasicUrl} from '@@server/config';
import dict from './dict.js'
const ftaxType=function(type){
  switch (type) {
    case 1:
      return '保税商品';
      break;
    case 2:
      return '直邮商品';
      break;
    case 3:
      return '完税商品';
      break;
    case 4:
      return '售后商品';
      break;
  }
};

const formatPrice=function(val){
  return (val/100).toFixed(2);
};

const imgAddBasicUrl=function(url){
  return imgBasicUrl+url;
};
const dictFilter = (val, type) => {
  if (dict[type]) {
    return dict[type][val] || ''
  }
  return ''
}
export default{
  dictFilter,
  ftaxType,
  formatPrice,
  imgAddBasicUrl
}
